<template>
  <div>
    <h4>Assessment View</h4>
    <b-container class="bv-example-row">
      <template v-if="assessments.length">
        <!-- <b-row> -->
          <AssessmentCard
            :assessment_id="assessment_id"
            :tree_level=0
            >
          </AssessmentCard>
        <!-- </b-row> -->
    </template>
    </b-container>
    
  </div>
</template>

<script>
export default {
  name: "GenericAssessmentView",
  components: {
    AssessmentCard: () => import("./AssessmentCard"),
  },
  data() {
    return {
      assessment_id: 1,
      assessment_types: [],
      assessments: [],
    };
  },
  computed: {
    rootAssessment() {
      return this.assessments.find((x) => x.id == this.assessment_id);
    },
  },
  methods: {
    fetchAssessmentTypes() {
      this.$restful.Get(`/assessment/assessment-type/`).then((response) => {
        this.assessment_types = response;
      });
    },
    fetchAssessments() {
      this.$restful.Get(`/assessment/assessment/`).then((response) => {
        this.assessments = response;
      });
    },
  },
  created() {
    this.fetchAssessmentTypes();
    this.fetchAssessments();
  },
};
</script>

<style scoped>
</style>